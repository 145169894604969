import React from 'react';
import { Button, MenuItem, TextField } from '@material-ui/core'
import axios from 'axios'
import './food_search_box.css'
import { Alert } from '@material-ui/lab';
//  import FoodSearchRow from './branded_product_search_row';
import { CartContext } from 'contexts/cart_context';

import LoopIcon from '@material-ui/icons/Loop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Search from '@material-ui/icons/Search';
import BarcodeIcon from 'images/barcode-scanning.png'

import FoodSearchBoxRow from './food_search_box_row';
import BarcodeScanner from './barcode_scanner';
import { Capacitor } from '@capacitor/core';

const FoodSearchBox = (props) => {
  const favoriteFoodList = React.useContext(CartContext).favoriteFoodList
  const [foodText, setFoodText] = React.useState("")
  const [foodList, setFoodList] = React.useState(favoriteFoodList)
  const [hasFoundFood, setHasFoundFood] = React.useState(1)
  const [errorState, setErrorState] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [foodMRU, setFoodMRU] = React.useState([])
  const [barcodeScanDialogOpen, setBarcodeScanDialogOpen] = React.useState(false)
  const [tempKey, setTempKey] = React.useState(0)

  React.useEffect(() => {

    if (hasFoundFood === 1 & favoriteFoodList.length > foodList.length) {
      setFoodList(favoriteFoodList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteFoodList]);


  async function queryForFoods(term) {

    setHasFoundFood(2)
    setErrorState(0)
    let productText = term

    if (!term) {
      setFoodList([])
      return
    }

    try {
      setIsLoading(true)
      const resp = await axios.get('/food-search/' + encodeURIComponent(productText));
      if (resp.data.length === 0) {
        setHasFoundFood(0)
      }
      for (let foodItem of resp.data) {
        foodItem.servingChosen = 0
        foodItem.portionChosen = 1
      }
      setFoodList(resp.data)
      setIsLoading(false)
    }
    catch {
      setFoodList([])
      setErrorState(1)
      setIsLoading(false)
    }
  }


  function openBarcodeDialog() {
    setTempKey((new Date()).getTime())
    setBarcodeScanDialogOpen(true)
  }

  const handleFoodTextChange = (e) => {
    setFoodText(e.target.value)

    if (hasFoundFood === 1) {
      //filter foods
      let filteredFoods = []
      for (let favoriteItem of favoriteFoodList) {
        if (favoriteItem.type === "meal") {
          if (favoriteItem.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            filteredFoods.push(favoriteItem)
          }
        }
        else {
          if (favoriteItem.food_name.toLowerCase().includes(e.target.value.toLowerCase())) {
            filteredFoods.push(favoriteItem)
          }
        }
      }
      setFoodList(filteredFoods)
    }

    axios.get('/food-search/autocomplete/' + encodeURIComponent(e.target.value)).then(resp => {
      if (resp.data.length !== 0) {
        resp.data[0] = resp.data[0] + " "
        setFoodMRU(resp.data)
      }
      else {
        setFoodMRU([])
      }
    }
    )
  }
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      queryForFoods(foodText)
    }
  }


  const handleAutoCompleteClick = (e, o, r) => {
    if (r === "reset") {
      setFoodText(foodMRU[parseInt(e.target.getAttribute('data-option-index'))])

      queryForFoods(foodMRU[parseInt(e.target.getAttribute('data-option-index'))])
    }
    if (e.target.value !== 0 && (e.target.value === "" || !e.target.value)) {
      setFoodList(favoriteFoodList)
      setHasFoundFood(1)
    }
  }



  let rows = []
  let servingOptions = {}

  for (let index in foodList) {
    let food = foodList[index]
    servingOptions[food.food_id] = []

    let foodInFavorites = false;
    for (let favoriteFood of favoriteFoodList) {
      if (favoriteFood.food_id === food.food_id) {
        foodInFavorites = true
        break
      }
    }
    if (food.type && food.type === "meal") {
      rows.push(<FoodSearchBoxRow food={food} foodList={foodList} setFoodList={setFoodList} index={index} foodInFavorites={foodInFavorites}
        calendarCategory={props.calendarCategory} calendarDate={props.calendarDate} />)
    }
    else {
      for (let servingIndex in food.servings.serving) {
        servingOptions[food.food_id].push(
          <MenuItem style={{ paddingRight: '0px', paddingLeft: '2px' }} index={index} key={food.servings.serving[servingIndex].serving_description} value={servingIndex}>
            {Capacitor.getPlatform() === "web" ? food.servings.serving[servingIndex].serving_description :
              <>{food.servings.serving[servingIndex].serving_description}</>}

          </MenuItem>
        )
      }


      if (food.food_id)
        rows.push(<FoodSearchBoxRow food={food} foodList={foodList} setFoodList={setFoodList} index={index} servingOptions={servingOptions} foodInFavorites={foodInFavorites} calendarCategory={props.calendarCategory} calendarDate={props.calendarDate} />)
    }
  }
  return (<>
    <div style={{ width: 'calc(100vw - 30px)', maxWidth: '500px' }} >

      <div className="branded-product-search-box">

        <Autocomplete
          id="free-solo-demo"
          freeSolo
          onInputChange={handleAutoCompleteClick}
          options={foodMRU}
          className="branded-product-search-textfield-parent"
          renderInput={(params) => (
            <TextField className="branded-product-search-textfield" {...params} placeholder="Search brand or food name" margin="normal" value={foodText} variant="outlined" onKeyDown={handleKeyDown} onChange={handleFoodTextChange} />
          )}
        />
        <Button className="branded-product-search-button" onClick={() => queryForFoods(foodText)}> <Search style={{ color: '#2D7933' }} /></Button>
        <Button className="branded-product-search-button" style={{ marginLeft: '5px' }} onClick={openBarcodeDialog}> <img alt="barcode" style={{ width: '25px', padding: '5px 0px' }} src={BarcodeIcon} /></Button>

      </div>
      <div className={rows.length ? "branded-product-search-results" : ''}>
        {rows}
        {rows.length !== 0 && <div style={{ color: 'grey', fontSize: '12px', textAlign: 'center', padding: '5px 0px 5px 0px' }}>Powered by FatSecret</div>}

      </div>
      {isLoading && <div style={{ textAlign: 'center' }}><LoopIcon style={{ animation: 'spin 2s linear infinite' }} /></div>}


      <div style={{ margin: "0px auto", width: "calc(100% - 30px)" }}>{errorState === 1 && <Alert severity="error">Error finding food, try a different search term</Alert>}
        {!hasFoundFood && <Alert severity="warning">No branded product found for that search term!</Alert>}
        {rows.length === 0 && errorState === 0 && hasFoundFood && favoriteFoodList.length === 0 && <div style={{ fontSize: '12px', color: 'grey', textAlign: 'center', width:'270px', margin:'10px auto'}}><>Use this to track any variety of food you are eating and access favorites.</></div>}
      </div>
    </div>

    {barcodeScanDialogOpen && <BarcodeScanner key={tempKey} handleExitClick={props.handleExitClick} calendarCategory={props.calendarCategory} barcodeScanDialogOpen={barcodeScanDialogOpen} setBarcodeScanDialogOpen={setBarcodeScanDialogOpen} calendarDate={props.calendarDate} close={() => { setBarcodeScanDialogOpen(false) }}></BarcodeScanner>}
  </>
  )
}
export default FoodSearchBox