import './account_page.css'
import React from "react";
import Button from '@material-ui/core/Button';
import { AuthContext } from 'contexts/auth_context';
import { CartContext } from 'contexts/cart_context';
import { UserContext } from 'contexts/user_context';

import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import HeightIcon from '@material-ui/icons/Height';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import { Dialog, InputAdornment, Select, TextField } from '@material-ui/core';

import { updateProfile, updateEmail, sendEmailVerification } from "firebase/auth"

import { activityMultiplierList, exerciseMultiplierList, goalMultiplierList } from 'components/shared/calorie_calculator/calorie_calculator';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import { Capacitor } from '@capacitor/core';
import DietaryPreferences from 'components/shared/dietary_preferences/dietary_preferences';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

const AccountPage = () => {
    const loadUsers = React.useContext(CartContext).loadUsers;
    const isNewlyWrittenUser = React.useContext(AuthContext).isNewlyWrittenUser;
    const { currentUser } = React.useContext(AuthContext);
    let userObject = React.useContext(UserContext).userObject;
    let setUserObject = React.useContext(UserContext).setUserObject;
    const [editNameDialogOpen, setEditNameDialogOpen] = React.useState(false);
    const [editEmailDialogOpen, setEditEmailDialogOpen] = React.useState(false);
    const [editAgeDialogOpen, setEditAgeDialogOpen] = React.useState(false);
    const [editSexDialogOpen, setEditSexDialogOpen] = React.useState(false);
    const [editHeightDialogOpen, setEditHeightDialogOpen] = React.useState(false);
    const [editActivityDialogOpen, setEditActivityDialogOpen] = React.useState(false);
    const [editExerciseDialogOpen, setEditExerciseDialogOpen] = React.useState(false);
    const [editGoalDialogOpen, setEditGoalDialogOpen] = React.useState(false);
    const [editDietaryDialogOpen, setEditDietaryDialogOpen] = React.useState(false);
    const [editLoading, setEditLoading] = React.useState(false);
    const [emailError, setEmailError] = React.useState('');
    const resetAllContexts = React.useContext(CartContext).resetAllContexts;
    const { logout } = React.useContext(AuthContext);

    React.useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewlyWrittenUser]);

    function exitDialogs() {
        setEditNameDialogOpen(false);
        setEditEmailDialogOpen(false);
        setEditAgeDialogOpen(false);
        setEditSexDialogOpen(false);
        setEditHeightDialogOpen(false);
        setEditActivityDialogOpen(false);
        setEditGoalDialogOpen(false);
        setEditDietaryDialogOpen(false);
        setEditLoading(false);
        setEditExerciseDialogOpen(false)
    }

    function changeName(e) {
        e.preventDefault();
        setEditLoading(true);
        const name = e.target.elements.name.value;
        updateProfile(currentUser, {
            displayName: name
        }).then(() => {
            console.log('Profile updated successfully');
        })
            .catch((error) => {
                console.error('Error updating profile:', error);
            });
        setTimeout(exitDialogs, 1500);
    }

    async function changeEmail(e) {
        e.preventDefault();
        setEditLoading(true);
        const newEmail = e.target.elements.email.value;
        updateEmail(currentUser, newEmail).then(() => {
            console.log('Profile updated successfully');
            sendEmailVerification(currentUser);
            exitDialogs();
        }).then(() => {
            console.log('Verification email sent to the new address');
        })
            .catch((error) => {
                setEmailError('Error updating profile: ' + error.message);
                setEditLoading(false);
            });
    }

    function changeAge(e) {
        e.preventDefault();
        setEditLoading(true);
        const age = e.target.elements.age.value;
        setUserObject({ ...userObject, age: age });
        exitDialogs();
    }

    function changeSex(e) {
        setUserObject({ ...userObject, sex: e.target.value });
        exitDialogs();
    }

    function changeHeight(e) {
        e.preventDefault();
        const heightFeet = e.target.elements.heightFeet.value;
        const heightInches = e.target.elements.heightInches.value;
        setUserObject({ ...userObject, heightFeet: heightFeet, heightInches: heightInches });
        exitDialogs();
    }

    function changeActivity(e) {
        setUserObject({ ...userObject, activityMultiplier: e.target.value });
        exitDialogs();
    }

    function changeExercise(e) {
        setUserObject({ ...userObject, exerciseMultiplier: e.target.value });
        exitDialogs();
    }

    function changeGoal(e) {
        setUserObject({ ...userObject, goalMultiplier: e.target.value });
        exitDialogs();
    }

    const selectedActivityMultiplier = (val) => {
        const selectedVal = activityMultiplierList.find((obj) => obj.value === parseFloat(val));
        return selectedVal ? selectedVal.name : '';
    }

    const selectedExerciseMultiplier = (val) => {
        const selectedVal = exerciseMultiplierList.find((obj) => obj.value === parseFloat(val));
        return selectedVal ? selectedVal.name : '';
    }

    const selectedGoalMultiplier = (val) => {
        const selectedVal = goalMultiplierList.find((obj) => obj.value === val);
        return selectedVal ? selectedVal.name : '';
    }

    let activityMultiplier = selectedActivityMultiplier(userObject.activityMultiplier);
    let exerciseMultiplier = selectedExerciseMultiplier(userObject.exerciseMultiplier);

    let goalMultiplier = selectedGoalMultiplier(userObject.goalMultiplier);

    return (
        <>
            <div className="meal-hover-card-frame">
                {Capacitor.getPlatform() !== "web" && <MobileSettingsButton isPlanning={true} />}

                <div className="meal-hover-card">
                    <div className="account-page-header">My Profile<Button className="account-page-sign-out-button" onClick={() => { logout(resetAllContexts) }}>Sign Out</Button></div>
                    <div className="account-page-subheader">General</div>
                    <div className="account-page-info">
                        <div className="account-page-row" onClick={() => { setEditNameDialogOpen(true) }}>
                            <PersonIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Name</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>{currentUser.displayName ? currentUser.displayName : ''}</div>
                        </div>

                        <div className="account-page-row" onClick={() => { setEditEmailDialogOpen(true) }}>
                            <EmailIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Email</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>{currentUser.email ? currentUser.email : ''}</div>
                        </div>
                        <div className="account-page-row" onClick={() => { setEditAgeDialogOpen(true) }}>
                            <CakeIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Age</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>{userObject.age ? userObject.age + " years old" : ''}</div>
                        </div>
                        <div className="account-page-row" onClick={() => { setEditSexDialogOpen(true) }}>
                            <AccessibilityNewIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Sex</div> <div style={{ textTransform: 'capitalize', color: 'grey', margin: '0px 10px 0px auto' }}>{userObject.sex ? userObject.sex : ''}</div>
                        </div>
                        <div className="account-page-row" style={{ border: 'none' }} onClick={() => { setEditHeightDialogOpen(true) }}>
                            <HeightIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Height</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>{userObject.heightFeet && userObject.heightInches ? (userObject.heightFeet + " ft " + userObject.heightInches + " in") : ''}</div>
                        </div>
                    </div>

                    <div className="account-page-subheader">Routine & Preferences</div>
                    <div className="account-page-info">
                        <div className="account-page-row" onClick={() => { setEditActivityDialogOpen(true) }}>
                            <DirectionsRunIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Daily Lifestyle</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>{activityMultiplier ? activityMultiplier : ''}</div>
                        </div>
                        <div className="account-page-row" onClick={() => { setEditExerciseDialogOpen(true) }}>
                            <FitnessCenterIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Exercise Level</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>{exerciseMultiplier ? exerciseMultiplier : ''}</div>
                        </div>
                        <div className="account-page-row" onClick={() => { setEditGoalDialogOpen(true) }}>
                            <GpsNotFixedIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Goal</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>{goalMultiplier ? goalMultiplier : ''}</div>
                        </div>
                        <div className="account-page-row" style={{ border: 'none' }} onClick={() => { setEditDietaryDialogOpen(true) }}>
                            <FastfoodIcon style={{ marginRight: '8px', marginLeft: '10px', color: 'rgb(45, 121, 51)' }} /> <div style={{ fontFamily: 'Montserrat-Medium', textAlign: 'left', margin: '0 10px 0 0' }}>Dietary Preferences</div> <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>Tap to edit</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Name Dialog */}
            <Dialog open={editNameDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <form onSubmit={changeName}>
                        <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Name</div>
                        <TextField InputProps={{ startAdornment: (<InputAdornment position="start"><PersonIcon style={{ color: 'grey' }} /></InputAdornment>) }} defaultValue={currentUser.displayName} style={{ width: '100%', margin: '30px 0px 30px 0px' }} type="text" name="name" placeholder="Name" />
                        <Button disabled={editLoading} type="submit" className="edit-info-button">Confirm</Button>
                    </form>
                </div>
            </Dialog>

            {/* Edit Email Dialog */}
            <Dialog open={editEmailDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <form onSubmit={changeEmail}>
                        <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Email</div>
                        <TextField InputProps={{ startAdornment: (<InputAdornment position="start"><EmailIcon style={{ color: 'grey' }} /></InputAdornment>) }} defaultValue={currentUser.email} style={{ width: '100%', margin: '30px 0px 30px 0px' }} type="email" name="email" placeholder="Email" />
                        {emailError && <div className="edit-info-dialog-error">{emailError}</div>}
                        <Button disabled={editLoading} type="submit" className="edit-info-button">Confirm</Button>
                    </form>
                </div>
            </Dialog>

            {/* Edit Age Dialog */}
            <Dialog open={editAgeDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <form onSubmit={changeAge}>
                        <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Age</div>
                        <TextField InputProps={{ startAdornment: (<InputAdornment position="start"><CakeIcon style={{ color: 'grey' }} /></InputAdornment>) }} defaultValue={userObject.age} style={{ width: '100%', margin: '30px 0px 30px 0px' }} type="number" name="age" placeholder="Age" />
                        <Button disabled={editLoading} type="submit" className="edit-info-button">Confirm</Button>
                    </form>
                </div>
            </Dialog>

            {/* Edit Sex Dialog */}
            <Dialog open={editSexDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Sex</div>
                    <div style={{ margin: '10px 20px' }}>

                        <Select defaultValue={userObject.sex} native onChange={changeSex}>
                            <option value="" disabled>Select sex</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </Select>
                    </div>
                    <Button disabled={editLoading} onClick={exitDialogs} className="edit-info-button">Confirm</Button>
                </div>
            </Dialog>

            {/* Edit Height Dialog */}
            <Dialog open={editHeightDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <form onSubmit={changeHeight}>
                        <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Height</div>
                        <div className="height-dialog-content">
                            <TextField InputProps={{ startAdornment: (<InputAdornment position="start"><HeightIcon style={{ color: 'grey' }} /></InputAdornment>) }} defaultValue={userObject.heightFeet} style={{ width: '45%', margin: '30px 2.5% 30px 2.5%' }} type="number" name="heightFeet" placeholder="Feet" />
                            <TextField InputProps={{ startAdornment: (<InputAdornment position="start"><HeightIcon style={{ color: 'grey' }} /></InputAdornment>) }} defaultValue={userObject.heightInches} style={{ width: '45%', margin: '30px 2.5% 30px 2.5%' }} type="number" name="heightInches" placeholder="Inches" />
                        </div>
                        <Button disabled={editLoading} type="submit" className="edit-info-button">Confirm</Button>
                    </form>
                </div>
            </Dialog>

            {/* Edit Activity Dialog */}
            <Dialog open={editActivityDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Daily Lifestyle</div>
                    <div style={{ margin: '10px 20px' }}>
                        <Select defaultValue={userObject.activityMultiplier} native onChange={changeActivity}>
                            {activityMultiplierList.map((activity) => (
                                <option key={activity.value} value={activity.value}>{activity.name}</option>
                            ))}
                        </Select>
                    </div>
                    <Button disabled={editLoading} onClick={exitDialogs} className="edit-info-button">Confirm</Button>
                </div>
            </Dialog>

            <Dialog open={editExerciseDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Exercise Level</div>
                    <div style={{ margin: '10px 20px' }}>
                        <Select defaultValue={userObject.exerciseMultiplier} native onChange={changeExercise}>
                            {exerciseMultiplierList.map((activity) => (
                                <option key={activity.value} value={activity.value}>{activity.name}</option>
                            ))}
                        </Select>
                    </div>
                    <Button disabled={editLoading} onClick={exitDialogs} className="edit-info-button">Confirm</Button>
                </div>
            </Dialog>

            {/* Edit Goal Dialog */}
            <Dialog open={editGoalDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <div className="account-page-subheader" style={{ textAlign: 'center' }}>Edit Goal</div>
                    <div style={{ margin: '10px 20px' }}>

                        <Select defaultValue={userObject.goalMultiplier} native onChange={changeGoal}>
                            {goalMultiplierList.map((goal) => (
                                <option key={goal.value} value={goal.value}>{goal.name}</option>
                            ))}
                        </Select>
                    </div>
                    <Button disabled={editLoading} onClick={exitDialogs} className="edit-info-button">Confirm</Button>
                </div>
            </Dialog>

            {/* Edit Dietary Preferences Dialog */}
            <Dialog open={editDietaryDialogOpen} onClose={exitDialogs}>
                <div className="edit-info-dialog-container">
                    <DietaryPreferences userObject={userObject} setUserObject={setUserObject} />
                    <Button disabled={editLoading} onClick={exitDialogs} className="edit-info-button">Close</Button>
                </div>
            </Dialog>
        </>
    );
}

export default AccountPage;
