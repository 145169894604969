import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from 'contexts/user_context';
import './water_tracker_card.css';
import OpacityIcon from '@material-ui/icons/Opacity';
import Tooltip from '@material-ui/core/Tooltip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Slider,
  TextField,
  Button,
} from '@material-ui/core';

const WaterTrackerCard = ({ calendarDate }) => {
  const {
    userObject,
    getWaterIntake,
    setWaterIntake,
    getWaterGoalCups,
    getCupSizeOz,
    setWaterSettings, // Import the new function
  } = useContext(UserContext);

  const [cups, setCups] = useState(0);
  const [waterGoalCupsState, setWaterGoalCupsState] = useState(getWaterGoalCups());
  const [cupSizeOzState, setCupSizeOzState] = useState(getCupSizeOz());
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    const initialCups = getWaterIntake(calendarDate);
    setCups(initialCups);
  }, [calendarDate, getWaterIntake]);

  useEffect(() => {
    setWaterGoalCupsState(getWaterGoalCups());
    setCupSizeOzState(getCupSizeOz());
  }, [userObject.waterGoalCups, userObject.cupSizeOz]);

  const handleDropClick = (index) => {
    const isFilled = index < cups;
    let newCups = cups;

    if (isFilled) {
      newCups = Math.max(0, cups - 1);
    } else {
      newCups = Math.min(100, cups + 1);
    }

    setCups(newCups);
    setWaterIntake(calendarDate, newCups);
  };

  return (
    <div className="water-tracker-card">
      <div className="water-tracker-header">
        <div className="header-left">Water</div>
        <div className="header-center">
          {cups * cupSizeOzState} oz / {waterGoalCupsState * cupSizeOzState} oz
        </div>
        <div className="header-right">
          <MoreHorizIcon
            className="settings-icon"
            onClick={() => setSettingsOpen(true)}
            style={{ cursor: 'pointer', color:'grey'}}
          />
        </div>
      </div>
      <div className="water-drops-container">
        {Array.from({ length: Math.max(cups, waterGoalCupsState) }, (_, index) => (
          <Tooltip
            key={index}
            title={
              index < cups ? 'Click to remove one cup' : 'Click to add one cup'
            }
          >
            <OpacityIcon
              className={`water-drop ${index < cups ? 'filled' : 'empty'}`}
              onClick={() => handleDropClick(index)}
              style={{ fontSize: '30px', margin: '2px', cursor: 'pointer' }}
              aria-label={
                index < cups
                  ? `Remove one cup of water (currently ${cups * cupSizeOzState} oz)`
                  : `Add one cup of water (currently ${cups * cupSizeOzState} oz)`
              }
            />
          </Tooltip>
        ))}
      </div>

      {/* Settings Dialog */}
      <Dialog open={settingsOpen} onClose={() => {
              setWaterSettings(waterGoalCupsState, cupSizeOzState);
              setSettingsOpen(false);
            }}>
        <DialogTitle>Water Tracker Settings</DialogTitle>
          <div className="settings-content" style={{padding:'0px 20px'}}>
            <div className="settings-item">
              <p>Daily Goal (Cups): {waterGoalCupsState}</p>
              <Slider
              style={{color:'black'}}
                value={waterGoalCupsState}
                onChange={(e, newValue) => setWaterGoalCupsState(newValue)}
                aria-labelledby="water-goal-cups-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={4}
                max={20}
              />
            </div>
            <div className="settings-item" style={{marginTop:'10px'}}>
              <TextField
                label="Cup Size (oz)"
                type="number"
                value={cupSizeOzState}
                onChange={(e) =>
                  setCupSizeOzState(parseFloat(e.target.value))
                }
                inputProps={{ min: 1, max: 64 }}
                fullWidth
                variant="outlined"
              />
            </div>
          </div>
        <DialogActions>
          <Button
            onClick={() => {
              setWaterSettings(waterGoalCupsState, cupSizeOzState);
              setSettingsOpen(false);
            }}
            color="primary"
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WaterTrackerCard;
