import React from 'react';
import { Button, Select, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import './food_search_box.css';
//  import FoodSearchRow from './branded_product_search_row';
import { getFoodNutrientAmount, getGramsFromServing } from 'helpers/recipe_helpers';
import { config } from 'Constants';
import _ from 'lodash';
import { CartContext } from 'contexts/cart_context';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
// import { ActionSheet } from '@capacitor/action-sheet';
import { Capacitor } from '@capacitor/core';
import SwipeableTemporaryDrawer from '../calendar/SwipeableTemporaryDrawer';
import DoneIcon from '@material-ui/icons/Done';
import MealDetailsCard from '../meal_details_card/meal_details_card';

const FoodSearchBoxRow = (props) => {
  const addMealToColumn = React.useContext(CartContext).addOrMoveMealToColumn;
  const addFavoriteFood = React.useContext(CartContext).addFavoriteFood;
  const removeFoodFromFavorites = React.useContext(CartContext).removeFoodFromFavorites;

  const [isActionSheetOpen, setIsActionSheetOpen] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [foodToRemove, setFoodToRemove] = React.useState(null);
  const [showCheckmark, setShowCheckmark] = React.useState(null); // Change this line
  const [mealBeingViewed, setMealBeingViewed] = React.useState(false)

  let food = props.food;
  let foodList = props.foodList;
  let setFoodList = props.setFoodList;
  let index = props.index;
  let servingOptions = props.servingOptions;
  let foodInFavorites = props.foodInFavorites;
  const servingChosen = servingOptions ? food.servings.serving[food.servingChosen] : null;

  const addFoodToCalendar = (e) => {
    if (food.type && food.type === 'meal') {
      addMealToColumn(props.calendarDate, food, props.calendarCategory ? props.calendarCategory : 'breakfast');
    } else {
      let mealObject = _.cloneDeep(config.emptyRecipe);
      mealObject = props.calendarCategory ? { ...mealObject, calendarCategory: props.calendarCategory } : { ...mealObject, calendarCategory: 'breakfast' };

      let foodChosen = foodList[e.currentTarget.value];
      mealObject = {
        ...mealObject,
        portion: foodChosen.portionChosen,
        name: foodChosen.food_name,
        description: foodChosen.brand_name ? foodChosen.brand_name + ' - a portion is ' + foodChosen.servings.serving[foodChosen.servingChosen].serving_description : 'a portion is ' + foodChosen.servings.serving[foodChosen.servingChosen].serving_description,
        areNutrientsManual: true,
        picture: foodChosen.picture,
      };

      for (let mealNutrient of mealObject.nutrition.nutrients) {
        if (mealNutrient.name === 'Calories') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Calories') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Protein') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Protein') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Carbohydrates') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Carbohydrates') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Fat') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Fat') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Cholesterol') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Cholesterol') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Sugar') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Sugar') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Sodium') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Sodium') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Calcium') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Calcium') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Saturated Fat') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Saturated Fat') * foodChosen.portionChosen);
        }
        if (mealNutrient.name === 'Fiber') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(foodChosen, 'Fiber') * foodChosen.portionChosen);
        }
      }
      addMealToColumn(props.calendarDate, mealObject, mealObject['calendarCategory']);
    }
    setShowCheckmark(true);
    setTimeout(() => {
      setShowCheckmark(null);
    }, 1000); // Duration of the animation
  };


  function addCheckMarkAfterAddToCalendar() {
    setShowCheckmark(true);
    setTimeout(() => {
      setShowCheckmark(null);
    }, 1000); // Duration of the animation
  }

  function handleUnitSelectChange(event) {
    let index = parseInt(event.currentTarget.getAttribute('index'));
    let foodListClone = foodList.concat([]);
    foodListClone[index].servingChosen = event.target.value;
    setFoodList(foodListClone);
  }

  function handleActionSheetChange(value) {
    let foodListClone = foodList.concat([]);
    foodListClone[index].servingChosen = parseInt(value);
    setFoodList(foodListClone);
  }

  function setPortion(portion, index) {
    let foodListClone = foodList.concat([]);
    foodListClone[index].portionChosen = portion;
    setFoodList(foodListClone);
    if (mealBeingViewed) {
      for (let mealNutrient of mealBeingViewed.nutrition.nutrients) {
        if (mealNutrient.name === 'Calories') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Calories') * portion);
        }
        if (mealNutrient.name === 'Protein') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Protein') * portion);
        }
        if (mealNutrient.name === 'Carbohydrates') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Carbohydrates') * food.portionChosen);
        }
        if (mealNutrient.name === 'Fat') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Fat') * food.portionChosen);
        }
        if (mealNutrient.name === 'Cholesterol') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Cholesterol') * food.portionChosen);
        }
        if (mealNutrient.name === 'Sugar') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Sugar') * food.portionChosen);
        }
        if (mealNutrient.name === 'Sodium') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Sodium') * food.portionChosen);
        }
        if (mealNutrient.name === 'Calcium') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Calcium') * food.portionChosen);
        }
        if (mealNutrient.name === 'Saturated Fat') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Saturated Fat') * food.portionChosen);
        }
        if (mealNutrient.name === 'Fiber') {
          mealNutrient['amount'] = Math.round(getFoodNutrientAmount(food, 'Fiber') * food.portionChosen);
        }
      }
      setMealBeingViewed({ ...mealBeingViewed, portion: portion })

    }
  }

  const confirmRemoveFromFavorites = (index) => {
    setFoodToRemove(index);
    setIsDialogOpen(true);
  };

  const handleRemoveFromFavorites = () => {
    let foodChosen = foodList[foodToRemove];
    removeFoodFromFavorites(foodChosen.food_id);
    let updatedFoodList = foodList.concat([]);
    updatedFoodList.splice(foodToRemove, 1);
    setFoodList(updatedFoodList);
    setIsDialogOpen(false);

  };

  const addToFavorites = (e) => {
    let foodChosen = foodList[e.currentTarget.value];
    addFavoriteFood(foodChosen);
  };



  const handleMealViewClick = () => {
    let mealObject = _.cloneDeep(config.emptyRecipe);
    mealObject = props.calendarCategory
      ? { ...mealObject, calendarCategory: props.calendarCategory }
      : { ...mealObject, calendarCategory: 'breakfast' };
  
    mealObject = {
      ...mealObject,
      portion: food.portionChosen || food.portion || 1,
      name: food.food_name || food.name,
      description: food.brand_name
        ? food.brand_name + ' - a portion is ' + food.servings.serving[food.servingChosen].serving_description
        : food.description || '',
      areNutrientsManual: true,
      picture: food.picture,
    };
  
    // Copy over other necessary properties from 'food' to 'mealObject'
    // For example, if 'food' has 'ingredients', 'instructions', etc.
  
    // Calculate nutrient amounts
    for (let mealNutrient of mealObject.nutrition.nutrients) {
      const nutrientAmount = getFoodNutrientAmount(food, mealNutrient.name);
      mealNutrient['amount'] = Math.round(nutrientAmount * (food.portionChosen || food.portion || 1));
    }
  
    setMealBeingViewed(mealObject);
  };

  const handleMealViewExitClick = () => {
    setMealBeingViewed(null)

  }

  return (
    <div style={{ borderBottom: '1px solid #e1e1e1' }}>
      <div key={`calendar-add-menu-meal-row-${food.food_id}`} className="calendar-add-menu-meal-row" style={{ marginBottom: '10px', maxWidth: '100%' }}>
        <img className="food-search-meal-picture" src={food.picture} alt={food.food_name} onClick={handleMealViewClick} />

        <div className="calendar-add-menu-meal-column" style={{ overflow: 'hidden' }}>
          <div style={{ display: 'flex', maxWidth: '100%' }}>
            <div className="calendar-add-menu-meal-name" style={{ margin: '7px 10px 7px 0px' }}>{food.food_name}
              {food.brand_name && <span className="branded-product-search-brand">{food.brand_name}</span>}
            </div>
          </div>
          <div className="food-search-box-inner-row">

            <div style={{ maxWidth: '74%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>

              {getFoodNutrientAmount(food, 'Calories') > 0 && <div className="calendar-add-custom-meal-caloric-info">
                <span className="caloric-chip caloric-chip-calories">{Math.round(getFoodNutrientAmount(food, 'Calories') * food.portionChosen)} Cals</span>
                <span className="caloric-chip caloric-chip-protein">{Math.round(getFoodNutrientAmount(food, 'Protein') * food.portionChosen)}g</span>
                <span className="caloric-chip caloric-chip-carbs">{Math.round(getFoodNutrientAmount(food, 'Carbohydrates') * food.portionChosen)}g</span>
                <span className="caloric-chip caloric-chip-fats">{Math.round(getFoodNutrientAmount(food, 'Fat') * food.portionChosen)}g</span>

              </div>}

              <div>
                {servingOptions && <div className="" style={{ display: 'flex', alignItems: 'center', marginBottom: '-2px', marginTop: '5px' }} >
                  {Capacitor.getPlatform() === 'web' ?
                    <Select
                      label="unit"
                      value={food.servingChosen}
                      name="unit"
                      onChange={handleUnitSelectChange}
                      className="modify-recipe-unit-select"
                      style={{ maxWidth: '75%' }}
                      disableUnderline
                    >
                      {servingOptions[food.food_id]}
                    </Select>
                    :
                    <div
                      onClick={() => {
                        setIsActionSheetOpen(true);
                      }}

                    >
                      <><div style={{ display: 'flex', maxWidth: '155px', overflow: 'hidden', fontSize: '13px' }}>{servingOptions[food.food_id].find((obj) => parseInt(obj.props.value) === food.servingChosen)}
                        <span><ArrowDropDownIcon style={{ paddingTop: '8px', paddingBottom: '-8px', color: '#c5c5c5' }}></ArrowDropDownIcon></span></div> </>
                    </div>
                  }
                  {(servingChosen.serving_description !== "100 g" || (food.portionChosen && food.portionChosen !== 1)) && <div style={{ marginLeft: '6px', fontSize: '12px', fontFamily: 'Montserrat-Medium', color: '#b4b4b4' }}>
                    {Math.round(getGramsFromServing(servingChosen) * food.portionChosen)}g</div>}

                </div>
                }
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                  Servings: <span style={{ marginLeft: '10px', marginBottom: '-6px', width: '400px' }}><MealPortioningSlider portion={food.portionChosen} setPortion={setPortion} index={index} shrink={true} smallPopup={true} /></span>
                </div>
              </div>
            </div>
            <div className="calendar-add-menu-meal-column" style={{ maxWidth: '100px' }}>
              <button className="calendar-add-menu-meal-button" disabled={showCheckmark} variant="contained" color="primary" value={index} onClick={addFoodToCalendar}>{showCheckmark ? <div className="checkmark-animation"><DoneIcon /></div> : "Add"}</button>
              {foodInFavorites ? <Tooltip title="Remove from favorites">
                <Button style={{ color: '#ff7c93' }} value={index} onClick={() => confirmRemoveFromFavorites(index)}><FavoriteIcon /></Button>
              </Tooltip> :
                <Tooltip title="Add to favorites">
                  <Button style={{ color: 'grey' }} value={index} onClick={addToFavorites}><FavoriteBorderIcon /></Button>
                </Tooltip>
              }
            </div>
          </div>
        </div>
      </div>
      {servingOptions && <SwipeableTemporaryDrawer
        actionSheetOpen={isActionSheetOpen}
        setActionSheetOpen={setIsActionSheetOpen}
        list={servingOptions[food.food_id]}
        onChange={(ind) => {
          handleActionSheetChange(servingOptions[food.food_id][ind].props.value);
        }}
      />}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          Are you sure you want to remove this item from favorites?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemoveFromFavorites} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={mealBeingViewed} maxWidth={'xl'} >
        <MealDetailsCard setPortion={setPortion} index={index} portion={mealBeingViewed ? mealBeingViewed.portion : null} handleExitClick={handleMealViewExitClick} meal={mealBeingViewed} disableCopy={true} addToCalendarEnabled={true} handleAddToCalendar={addCheckMarkAfterAddToCalendar} />
      </Dialog>

    </div>
  );
};
export default FoodSearchBoxRow;
