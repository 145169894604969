// CalendarSlot.js

import React, { useContext } from 'react';
import CalendarMeal from './calendar_meal';
import CalendarMealNoDrag from './calendar_meal_no_drag';
import { useMultiDrop } from 'react-dnd-multi-backend';
import { ItemTypes } from '../item_types/item_types';
import { CartContext } from 'contexts/cart_context';
import CalendarAddMeal from './calendar_add_meal';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LoopIcon from '@material-ui/icons/Loop';
import Button from '@material-ui/core/Button';
import { Dialog, Tooltip } from '@material-ui/core';
import { UserContext } from 'contexts/user_context';
import CalorieBox from '../modify_calories/calorie_box';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import ContentCopy from 'images/content_copy.svg';
import CopyMealsToDay from '../copy_meals_to_day/copy_meals_to_day';
import CalendarSlotIcon from 'images/calendar_slot_icon.png';

const CalendarSlot = (props) => {
  const canMoveMeal = useContext(CartContext).canMoveMeal;
  const addOrMoveMealToColumn = useContext(CartContext).addOrMoveMealToColumn;
  const [isGenerateLoading, setIsGenerateLoading] = React.useState(false);
  const [generateCaloriesOpen, setGenerateCaloriesOpen] = React.useState(false);
  const [copyToDayDialogOpen, setCopyToDayDialogOpen] = React.useState(false);
  const generateDailyMeals = React.useContext(CartContext).generateDailyMeals;
  const getCalorieCategory = React.useContext(UserContext).getCalorieCategory();
  const recipeList = React.useContext(RecipeBooksContext).recipeList;
  const [[dropProps, drop]] = useMultiDrop({
    accept: ItemTypes.MEAL,

    drop: (item, monitor) =>
      addOrMoveMealToColumn(props.calendarDate, item.meal, props.calendarCategory),
    canDrop: (item, monitor) => canMoveMeal(props.calendarDate, props.calendarCategory, item.meal),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const handleExitClick = () => {
    setGenerateCaloriesOpen(false);
  };

  async function handleGenerateClick(event) {
    if (getCalorieCategory <= 3700 && getCalorieCategory >= 1000) {
      setIsGenerateLoading(true);
      await generateDailyMeals(props.calendarDate, props.calendarCategory);
      setIsGenerateLoading(false);
    } else {
      setGenerateCaloriesOpen(true);
    }
  }

  function openCopyDaysDialog() {
    setCopyToDayDialogOpen(true);
  }

  function exitCopyDaysDialog() {
    setCopyToDayDialogOpen(false);
  }

  let meals = [];

  for (let meal in props.cartMeals) {
    meals.push(
      window.innerWidth > 500 ? (
        <CalendarMeal
          key={`meal-${meal}`}
          cartMeal={props.cartMeals[meal]}
          selectedMacros={props.selectedMacros} // Pass selectedMacros here
        />
      ) : (
        <CalendarMealNoDrag
          key={`meal-${meal}`}
          cartMeal={props.cartMeals[meal]}
          selectedMacros={props.selectedMacros} // Pass selectedMacros here
        />
      )
    );
  }

  let calendarDateObject = new Date(props.calendarDate);
  let isDayInPast = false;
  if (calendarDateObject - new Date() < -86400000) {
    isDayInPast = true;
  }

  if (meals.length === 0) {
    return (
      <>
        <div className="calendar-empty-slot-wrapper" ref={drop}>
          <div className="calendar-empty-slot">
            <CalendarAddMeal
              content={
                <>
                  <img
                    style={{ height: '40px', margin: '20px' }}
                    alt="calendar add"
                    src={CalendarSlotIcon}
                  />
                  <div>
                    <div
                      style={{
                        fontFamily: 'Montserrat-SemiBold',
                        fontSize: '14px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {props.calendarCategory}
                    </div>
                    <div style={{ fontSize: '11px', marginTop: '5px', color: '#82867E' }}>
                      Log & track your food!
                    </div>
                  </div>
                  <div style={{ marginLeft: 'auto', color: '#7c7c7c' }}>
                    <AddCircleIcon
                      style={{
                        fontSize: '20px',
                        color: '#4CB885',
                      }}
                    />
                  </div>
                </>
              }
              providedContent={true}
              calendarDate={props.calendarDate}
              calendarCategory={props.calendarCategory}
            />
          </div>
          {dropProps.isOver && dropProps.canDrop && <div className="calendar-hover-slot"></div>}
        </div>
        <Dialog open={generateCaloriesOpen} maxWidth={'xl'} onClose={handleExitClick}>
          <div className="input-calories-popover">
            <div className="input-calories-popover-header">
              Input valid daily calories or macros to generate
            </div>
            <CalorieBox />
          </div>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <div className="calendar-meal-slot" ref={drop}>
        <div className="calendar-meal-slot-top">
          <CalendarAddMeal
            content={
              <div className="calendar-slot-category">
                {props.calendarCategory}{' '}
                <AddCircleIcon
                  style={{
                    marginLeft: '5px',
                    fontSize: '18px',
                    color: '#4CB885',
                    marginTop: '-1px',
                  }}
                />
              </div>
            }
            providedContent={true}
            calendarDate={props.calendarDate}
            calendarCategory={props.calendarCategory}
          />

          <div>
            <Tooltip title="Copy meals to another day">
              <Button onClick={openCopyDaysDialog} className="calendar-slot-copy-button">
                <img
                  id="calendar-meal-duplicate"
                  className="landing-upsell-image"
                  src={ContentCopy}
                  alt={'content copy'}
                  style={{ height: '18px', opacity: '0.4', marginRight: '2px' }}
                />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div>{meals}</div>

        {dropProps.isOver && dropProps.canDrop && <div className="calendar-hover-slot"></div>}
      </div>
      <Dialog open={generateCaloriesOpen} maxWidth={'xl'} onClose={handleExitClick}>
        <div className="input-calories-popover">
          <div className="input-calories-popover-header">
            Input valid daily calories or macros to generate
          </div>
          <CalorieBox />
        </div>
      </Dialog>

      <Dialog open={copyToDayDialogOpen} maxWidth={'xl'} onClose={exitCopyDaysDialog}>
        <CopyMealsToDay
          exitCopyDaysDialog={exitCopyDaysDialog}
          meals={props.cartMeals}
          calendarCategory={props.calendarCategory}
        />
      </Dialog>
    </>
  );
};

export default CalendarSlot;
