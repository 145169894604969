// CalorieChart.js

import './calorie_chart.css';
import React, { useState } from "react";
import { getMealNutrientAmount } from "helpers/recipe_helpers";
import _ from 'lodash';
import CalorieCard from '../calorie_card/calorie_card';

const CalorieChart = (props) => {
  const [errorMessage, setErrorMessage] = useState("");  // State to manage error messages
  const [activeIndex, setActiveIndex] = useState(0); // State for carousel index

  // Parse and default nutrient values
  const parseNutrient = (value) => parseFloat(value) || 0;

  let totalCarbs = parseNutrient(getMealNutrientAmount(props.meal, "Carbohydrates"));
  let totalProteins = parseNutrient(getMealNutrientAmount(props.meal, "Protein"));
  let totalFats = parseNutrient(getMealNutrientAmount(props.meal, "Fat"));
  let totalCalories = parseNutrient(getMealNutrientAmount(props.meal, "Calories"));
  let totalSodium = parseNutrient(getMealNutrientAmount(props.meal, "Sodium"));
  let totalSugar = parseNutrient(getMealNutrientAmount(props.meal, "Sugar"));
  let totalCholesterol = parseNutrient(getMealNutrientAmount(props.meal, "Cholesterol"));
  let totalFiber = parseNutrient(getMealNutrientAmount(props.meal, "Fiber"));
  let totalSaturatedFat = parseNutrient(getMealNutrientAmount(props.meal, "Saturated Fat"));
  let totalNetCarbs = parseNutrient(getMealNutrientAmount(props.meal, "Net Carbohydrates"));

  const handleNutrientChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value < 0) { return; }
    if (e.target.name === "Fiber" && value > totalCarbs) {
      setErrorMessage("Fiber amount cannot be higher than carbohydrates");
      return;
    } else {
      setErrorMessage("");  // Clear error message if input is valid
    }

    let newRecipe = _.cloneDeep(props.meal);
    let nutrientFound = false;
    for (let recipeNutrient of newRecipe.nutrition.nutrients) {
      if (recipeNutrient.name === e.target.name) {
        recipeNutrient['amount'] = value;
        nutrientFound = true;
        break;
      }
    }
    if (!nutrientFound) {
      // If the nutrient is not in the array, add it
      newRecipe.nutrition.nutrients.push({ name: e.target.name, amount: value });
    }

    if (props.setStagedChanges) {
      props.setStagedChanges(true);
    }
    props.setRecipe(newRecipe);
  };

  return (
    <CalorieCard
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      totalProteins={totalProteins}
      totalCarbs={totalCarbs}
      totalFats={totalFats}
      totalSodium={totalSodium}
      totalSugar={totalSugar}
      totalCholesterol={totalCholesterol}
      totalFiber={totalFiber}
      totalNetCarbs={totalNetCarbs}
      totalSaturatedFat={totalSaturatedFat}
      totalCalories={totalCalories}
      context="recipe" // Use 'recipe' context to exclude user data
      editable={props.meal.areNutrientsManual && !props.isReadOnly} // Set editable based on areNutrientsManual
      handleNutrientChange={handleNutrientChange}
      errorMessage={errorMessage} // Pass error message
    />
  );
};

export default CalorieChart;
